import React, { useState, useEffect, memo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import filterItems from "~libs/filterItems";
import { useSelector, useDispatch } from "react-redux";
import SearcherList from "~styled/searcherList/SearcherList";
import { fetchAgencias } from "./actions";
import ListView from "~styled/listView/ListView";

import Next from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    paddingTop: 10,
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
    height: "inherit",
    paddingTop: 3,
    paddingBottom: 3,
    whiteSpace: "inherit",
  },
  chipLabel: {
    whiteSpace: "inherit",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.agenciasReducer.isLoading,
    agencias: state.agenciasReducer.agencias,
  }));

const AgenciaSelector = memo(({ value, onChange }) => {
  const [filter, setFilter] = useState("");

  const { isLoading, agencias } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAgencias());
  }, [dispatch]);

  const handleAgenciaItem = (value) => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      {!value.label && (
        <div className={classes.searcher}>
          <SearcherList
            onClear={() => setFilter("")}
            filter={filter}
            onChange={handleFilterChange}
            placeholder={"Buscar Agencia"}
          />
          <ListView
            isLoading={isLoading}
            onItemSelected={handleAgenciaItem}
            list={filter === "" ? agencias : filterItems(agencias, filter)}
            labelMostUsed=""
            IconMostUsed={Next}
            labelTodas="AGENCIAS"
            mostUsedList={[
              {
                label: "COTIZAR sin Agencia",
                value: null,
              },
            ]}
          />
        </div>
      )}
    </>
  );
});

export default AgenciaSelector;
