import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import DatosSiniestroCard from "./cards/DatosSiniestroCard";
import DatosAseguradoCard from "./cards/DatosAseguradoCard";
import DatosConductorCard from "./cards/DatosConductorCard";
import DatosAutomotorCard from "./cards/DatosAutomotorCard";
import FechaLugarCard from "./cards/FechaLugarCard";
import DetalleSiniestroCard from "./cards/DetalleSiniestroCard";
import DocumentacionCard from "./cards/DocumentacionCard";
import DatosTerceroCard from "./cards/DatosTerceroCard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "3.5%",
    minWidth: "10px",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  btnFinalizar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  cardItem: { minWidth: 300 },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.detalleSiniestroReducer.isLoading,
  }));

const DetalleSiniestro = () => {
  const classes = useStyles();
  const { isLoading } = useReduxSelector();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.cardsAndCuotasContainer}>
            <CardsColumn>
              <DatosSiniestroCard isLoading={isLoading} />
              <FechaLugarCard isLoading={isLoading} />
              <DetalleSiniestroCard isLoading={isLoading} />
              <DocumentacionCard isLoading={isLoading} />
            </CardsColumn>
            <CardsColumn>
              <DatosAseguradoCard isLoading={isLoading} />
              <DatosAutomotorCard isLoading={isLoading} />
              <DatosConductorCard isLoading={isLoading} />
              <DatosTerceroCard isLoading={isLoading} />
            </CardsColumn>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetalleSiniestro;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
