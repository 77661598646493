import React, { useEffect } from "react";
import { useAuth0 } from "./useAuth";
import LoadingView from "~styled/loadingView/LoadingView";

export const Callback = (props) => {
  const { handleAuthentication } = useAuth0();
  const { location } = props;

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication();
    } else console.log("hay error");
  }, [handleAuthentication, location]);

  return <LoadingView />;
};
