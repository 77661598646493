import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogActions = withStyles((theme) => ({
  root: {
    justifyContent: "center",
    padding: 24,
  },
}))(MuiDialogActions);

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        style={{
          fontSize: pxToRem(18),
          fontWeight: "bold",
          lineHeight: 1.89,
          letterSpacing: 0.45,
          textAlign: "center",
          color: "#663ea8",
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const Dialog = withStyles((theme) => ({
  paper: {
    borderRadius: 12,
    boxShadow: "none",
    width: "100%",
  },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: pxToRem(24),
    overflow: "hidden",
  },
  dividers: { borderBottom: "none" },
}))(MuiDialogContent);

const Modal = ({
  opened = false,
  onClose,
  title = "",
  dialogActions,
  withPadding = true,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={opened}
      TransitionComponent={Transition}
      transitionDuration={500}
      fullScreen={false}
      //onEntered={() => document.getElementById("dialogcontent1").scrollTo(0, 0)}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent
        id="dialogcontent1"
        dividers
        classes={withPadding ? undefined : { root: classes.rootWithNoPadding }}
        style={{ overflow: "auto" }}
      >
        {children}
      </DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
};
export default Modal;

const useStyles = makeStyles((theme) => ({
  rootWithNoPadding: {
    padding: 0,
  },
}));
