import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/core/styles";
import ItemLoadingCard from "../ItemLoadingCard";
import IconEdit from "~assets/icons/ic_edit.svg";
import Modal from "../Modal";
import { useQuery } from "react-query";
import { coberturas } from "~api/coberturas";
import CoberturaStepper from "./CoberturaStepper";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

const useGetCoberturas = (compania) => {
  const response = useQuery(
    compania,
    async (compania) => {
      try {
        return await coberturas().getCoberturasById({ id: compania });
      } catch (err) {
        console.error(err);
      }
    },
    { staleTime: 1000 * 60 * 15, enabled: compania } //15 min cache
  );

  return {
    isLoadingCoberturas: response.isLoading,
    coberturas: response?.data?.coberturasAutos,
  };
};

const errorText = "Debe seleccionar nuevamente una cobertura";

const ItemCoberturaCard = ({
  label,
  onChange,
  readOnly,
  isLoading,
  cobertura,
  compania,
  //
  codia,
  anio,
  codigoPostal,
  localidad,
  provincia,
  uso,
  condicionFiscal,
  tieneGNC,
  formaDePago,
  displayError,
  size,
}) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);

  const { isLoadingCoberturas, coberturas = [] } = useGetCoberturas(compania);

  const handleChangeValue = (premio, cobertura, cotizacionId) => {
    setEdit(false);

    if (onChange)
      onChange({
        premio: { value: premio, valid: true, focus: false },
        cobertura: { value: cobertura, valid: true, focus: false },
        cotizacionId: { value: cotizacionId, valid: true, focus: false },
      });
  };

  if (isLoading || isLoadingCoberturas)
    return <ItemLoadingCard label={label} />;

  const coberturaFormatted = getFormatted(coberturas, cobertura);

  return (
    <div
      className={classes.container}
      style={size === "small" ? { padding: "1%" } : {}}
    >
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        <Modal
          opened={edit}
          title="Selector de Cobertura"
          onClose={() => setEdit(false)}
        >
          <CoberturaStepper
            onChange={handleChangeValue}
            codia={codia}
            anio={anio}
            codigoPostal={codigoPostal}
            localidad={localidad}
            provincia={provincia}
            //uso={uso}
            //condicionFiscal={condicionFiscal}
            tieneGNC={tieneGNC}
            formaDePago={formaDePago}
            compania={compania}
          />
        </Modal>
        {!edit && (
          <>
            <Typography className={classes.value}>
              {coberturaFormatted}
            </Typography>
            {!readOnly && (
              <div className={classes.buttonLink} onClick={() => setEdit(true)}>
                <img alt="" src={IconEdit} className={classes.icon} />
              </div>
            )}
            {displayError && (
              <Tooltip title={errorText}>
                <div
                  className={classes.buttonLink}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#f77221",
                    paddingLeft: 4,
                    cursor: "pointer",
                  }}
                >
                  <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ItemCoberturaCard;

const getFormatted = (coberturas, id) => {
  const cobertura = coberturas?.find((x) => x.idExterno === id);
  return `(${cobertura?.tipoCobertura}) ${cobertura?.nombre}`;
};
