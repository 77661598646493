import React, { memo } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { processQueue, closeMessage } from "./actions";
import SnackBarContent from "./SnackBarContent";

const useReduxSelector = () =>
  useSelector((state) => ({
    currentMessage: state.snackbarReducer.currentMessage,
    variant: state.snackbarReducer.variant,
  }));

const SnackbarsProvider = memo(() => {
  const dispatch = useDispatch();

  const { currentMessage, variant } = useReduxSelector();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeMessage());
  };

  const handleExited = () => {
    dispatch(processQueue());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={currentMessage ? true : false}
      autoHideDuration={2000}
      onClose={handleClose}
      onExited={handleExited}
    >
      <SnackBarContent
        onClose={handleClose}
        variant={variant}
        message={currentMessage ? currentMessage.text : ""}
      />
    </Snackbar>
  );
});

export default SnackbarsProvider;
