import { useCallback, useMemo, useEffect } from "react";
import { useAuth0Context } from "./AuthContext";
import jwt_decode from "jwt-decode";
import getMGUser from "~libs/getMGUser";
import localRepository from "../../localRepository";

const useIsAuthenticated = (expiresAt) => {
  return useMemo(() => {
    return new Date().getTime() < expiresAt;
  }, [expiresAt]);
};

export const useRefreshToken = () => {
  const { authState, renewSession } = useAuth0();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  useEffect(() => {
    const TWO_MINUTES = 2 * 60 * 1000;
    let timeToExpire = 0;
    if (authState.expiresAt > 0) {
      timeToExpire = authState.expiresAt - new Date().getTime();
      timeToExpire =
        timeToExpire > TWO_MINUTES ? timeToExpire - TWO_MINUTES : 0;
    }

    const timer = setTimeout(() => {
      if (isLoggedIn) {
        renewSession();
      }
    }, timeToExpire);

    return () => clearTimeout(timer);
  }, [authState, renewSession, isLoggedIn]);
};

export const useAuth0 = () => {
  const { auth0, authState, updateAuthState } = useAuth0Context();
  const isAuthenticated = useIsAuthenticated(authState.expiresAt);

  const loginSocial = useCallback(
    (provider) => {
      auth0.authorize({
        scope: "openid profile email offline_access",
        connection: provider,
        audience: `http://mg-group.com.ar/apis`,
        prompt: "login",
      });
    },
    [auth0]
  );

  const logout = useCallback(() => {
    updateAuthState({
      accessToken: null,
      idToken: null,
      expiresAt: 0,
      user: null,
    });

    localStorage.removeItem("isLoggedIn");

    auth0.logout({
      returnTo: window.location.origin,
    });
  }, [auth0, updateAuthState]);

  const setSession = useCallback(
    (authResult) => {
      const auth0TokenData = jwt_decode(authResult.accessToken);

      const user = getMGUser(auth0TokenData);

      localStorage.setItem("isLoggedIn", true);

      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

      updateAuthState({
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        expiresAt: expiresAt,
        user: user,
      });
    },
    [updateAuthState]
  );

  const renewSession = useCallback(() => {
    auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setSession(authResult);
        localRepository.accessToken.set(authResult.accessToken);
      } else if (err) {
        console.error(err);
        console.log(
          `Could not get a new token (${err.error}: ${err.error_description}).`
        );
        logout();
      }
    });
    //eslint-disable-next-line
  }, []);

  const handleAuthentication = useCallback(() => {
    auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setSession(authResult);
      } else if (err) {
        renewSession();
        console.log(
          `Error: ${err.error}. Check the console for further details.`
        );
      }
    });
    //eslint-disable-next-line
  }, []);

  return {
    authState,
    isAuthenticated,
    loginSocial,
    handleAuthentication,
    renewSession,
    logout,
  };
};
