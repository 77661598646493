import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GalleryWithLightbox from "~styled/gallery/GalleryWithLightBox";

import FileUpload from "~components/styled/fileUpload/FileUpload";
import {
  setFotoLateralDerechoVehiculo,
  clearFotoLateralDerechoVehiculo,
} from "../actions";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";
import pxToRem from "~libs/pxToRem";
import fileService from "~libs/fileService";

import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";

import { Typography } from "@material-ui/core";
import ContentLoader from "react-content-loader";

const photoWidth = "320px";
const photoHeight = "180px";

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    display: "flex",
    width: "100%",
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
  },
  linkButton: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
    marginLeft: 5,
    marginTop: 5,
  },
  gallery: { marginLeft: `calc( (100% - ${photoWidth}) / 2 )` },
  removePhotoLink: { display: "flex", justifyConent: "center", width: "100%" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    fileNameDefault:
      state.multicotizadorReducer.fotoLateralDerechoVehiculoSelected.value,
  }));

const FotoLateralDerechoVehiculoStep = () => {
  const dispatch = useDispatch();

  const { fileNameDefault } = useReduxSelector();

  const [fileName, setFileName] = useState(fileNameDefault);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    dispatch(clearFotoLateralDerechoVehiculo());
  }, [dispatch]);

  const setUrl = async () => {
    if (fileName) {
      const url = (await fileService("temp").download(fileName)).url;
      setPhoto(url);
    }
  };

  useEffect(() => {
    setUrl();
    //eslint-disable-next-line
  }, [fileName]);

  const classes = useStyles();

  const isLoading = !photo && fileName;

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"Adjuntá una foto del lateral derecho del vehículo"}
        marginBottom={60}
      />
      {!isLoading && !photo && (
        <FileUpload
          onAdded={(event) => setFileName(event.target.value)}
          onRemoved={(event) => setFileName(null)}
          acceptImage={true}
          acceptPdf={false}
        />
      )}

      {fileName && (
        <>
          <div className={classes.gallery}>
            {!isLoading && (
              <GalleryWithLightbox
                imagenes={[
                  {
                    src: photo,
                    thumbnail: photo,
                    thumbnailWidth: photoWidth,
                    thumbnailHeight: photoHeight,
                  },
                ]}
              />
            )}
            {isLoading && (
              <ContentLoader
                height={photoHeight}
                width={photoWidth}
                speed={1}
                backgroundColor="#f7f8ff"
                foregroundColor="#d9e8df"
              >
                <rect
                  x="0"
                  y="0"
                  rx="4"
                  ry="4"
                  width={photoWidth}
                  height={photoHeight}
                />
              </ContentLoader>
            )}
          </div>

          <div className={classes.removePhotoLink}>
            <Typography className={classes.secondaryText}>
              <Link
                className={classes.linkButton}
                component="button"
                underline="always"
                onClick={() => {
                  setFileName(null);
                  setPhoto(null);
                }}
              >
                Eliminar foto
              </Link>
            </Typography>
          </div>
        </>
      )}

      <div style={{ paddingTop: pxToRem(24) }}>
        <CallToActionButton
          disabled={!photo}
          label={"Continuar"}
          onClick={() =>
            dispatch(
              setFotoLateralDerechoVehiculo({ value: fileName, selected: true })
            )
          }
        />
      </div>
    </MulticotizadorContent>
  );
};

export default FotoLateralDerechoVehiculoStep;
