import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: props.mainInput ? "none" : "1px solid #8B8B8B",
  }),
  body: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  caption: (props) => ({
    fontFamily: "Nunito",
    fontSize: props.mainInput ? "18px" : "16px",
    fontWeight: props.mainInput ? 500 : 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  }),
}));

const ItemLoadingCard = ({ label, size, mainInput }) => {
  const classes = useStyles({ mainInput });

  return (
    <div
      className={classes.container}
      style={size === "small" ? { padding: "1%" } : {}}
    >
      {label && <Typography className={classes.caption}>{label}</Typography>}
      <div className={classes.body}>
        <div style={{ width: "100%", height: "100%" }}>
          <ContentLoader
            height={20}
            width={"100%"}
            speed={1}
            backgroundColor="#f7f8ff"
            foregroundColor="#d9e8df"
          >
            <rect x="35" y="0" rx="4" ry="4" width="80%" height="20" />
          </ContentLoader>
        </div>
      </div>
    </div>
  );
};

export default ItemLoadingCard;
