import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import PeopleIcon from "@material-ui/icons/People";
import ItemInputCard from "~business/Cards/ItemInputCard";
import MultipleItemSelectorCard from "~business/Cards/MultipleItemSelectorCard";
import { siniestrosCompanias } from "~constants/companiasSiniestros";

import {
  setNombreTercero,
  setApellidoTercero,
  setNumeroDocumentoTercero,
  setTelefonoTercero,
  setCompaniaTercero,
  setNumeroPolizaTercero,
  setDominioTercero,
  setMarcaTercero,
  setModeloTercero,
  setDaniosTercero,
  setSubmitting,
} from "../actions";
import { CHOQUE } from "~constants/tipoSiniestro";

const useReduxSelector = () =>
  useSelector((state) => {
    return {
      nombre: state.cargaSiniestroPublicaReducer.nombreTerceroSelected,
      apellido: state.cargaSiniestroPublicaReducer.apellidoTerceroSelected,
      numeroDocumento:
        state.cargaSiniestroPublicaReducer.numeroDocumentoTerceroSelected,
      compania: state.cargaSiniestroPublicaReducer.companiaTerceroSelected,
      numeroPoliza:
        state.cargaSiniestroPublicaReducer.numeroPolizaTerceroSelected,
      telefono: state.cargaSiniestroPublicaReducer.telefonoTerceroSelected,
      dominio: state.cargaSiniestroReducer.dominioTerceroSelected,
      modelo: state.cargaSiniestroPublicaReducer.modeloTerceroSelected,
      marca: state.cargaSiniestroPublicaReducer.marcaTerceroSelected,
      danios: state.cargaSiniestroPublicaReducer.daniosTerceroSelected,
      tipo: state.cargaSiniestroPublicaReducer.tipoSelected.value,
    };
  });

const title = "Datos del Tercero";

const DatosTerceroCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const {
    nombre,
    apellido,
    numeroDocumento,
    numeroPoliza,
    compania,
    dominio,
    modelo,
    marca,
    telefono,
    danios,
    tipo,
  } = useReduxSelector();

  let timer = null;

  const handleChangeNombre = (nombre) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNombreTercero(nombre));
    }, 200);
  };

  const handleChangeApellido = (apellido) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setApellidoTercero(apellido));
    }, 200);
  };

  const handleChangeNumeroDocumento = (documento) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNumeroDocumentoTercero(documento));
    }, 200);
  };

  const handleChangeNumeroPoliza = (numeroPoliza) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNumeroPolizaTercero(numeroPoliza));
    }, 200);
  };

  const handleChangeCompania = (compania) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setCompaniaTercero(compania));
    }, 200);
  };

  const handleChangeTelefono = (telefono) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setTelefonoTercero(telefono));
    }, 200);
  };

  const handleChangeDominio = (dominio) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setDominioTercero(dominio));
    }, 200);
  };

  const handleChangeMarca = (marca) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setMarcaTercero(marca));
    }, 200);
  };

  const handleChangeModelo = (modelo) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setModeloTercero(modelo));
    }, 200);
  };

  const handleChangeDanios = (danios) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setDaniosTercero(danios));
    }, 200);
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  return (
    <>
      {tipo === CHOQUE && (
        <DetalleCard title={title} IconHeader={PeopleIcon}>
          <ItemInputCard
            id={"nombreTercero"}
            isLoading={isLoading}
            label={"Nombre"}
            value={nombre.value}
            onChange={handleChangeNombre}
            onBlur={handleBlur}
            focus={nombre.focus}
          />
          <ItemInputCard
            id={"apellidoTercero"}
            isLoading={isLoading}
            label={"Apellido"}
            value={apellido.value}
            onChange={handleChangeApellido}
            onBlur={handleBlur}
            focus={apellido.focus}
          />
          <ItemInputCard
            id={"numeroDocumentoTercero"}
            isLoading={isLoading}
            label={"DNI"}
            value={numeroDocumento.value}
            onChange={handleChangeNumeroDocumento}
            onBlur={handleBlur}
            focus={numeroDocumento.focus}
          />
          <MultipleItemSelectorCard
            id={"companiaTercero"}
            isLoading={isLoading}
            label={"Compañía"}
            value={compania.value}
            onChange={handleChangeCompania}
            onBlur={handleBlur}
            focus={compania.focus}
            tooltipContent={"Seleccionar Compañía"}
            items={siniestrosCompanias}
            placeholder={"Seleccionar"}
          />
          <ItemInputCard
            id={"polizaTercero"}
            isLoading={isLoading}
            label={"Número de Póliza"}
            value={numeroPoliza.value}
            onChange={handleChangeNumeroPoliza}
            onBlur={handleBlur}
            focus={numeroPoliza.focus}
          />
          <ItemInputCard
            id={"telefonoTercero"}
            isLoading={isLoading}
            label={"Teléfono"}
            value={telefono.value}
            onChange={handleChangeTelefono}
            onBlur={handleBlur}
            focus={telefono.focus}
          />
          <ItemInputCard
            id={"dominioTercero"}
            isLoading={isLoading}
            label={"Dominio"}
            value={dominio.value}
            onChange={handleChangeDominio}
            onBlur={handleBlur}
            focus={dominio.focus}
            required
          />
          <ItemInputCard
            id={"marcaTercero"}
            isLoading={isLoading}
            label={"Marca"}
            value={marca.value}
            onChange={handleChangeMarca}
            onBlur={handleBlur}
            focus={marca.focus}
          />
          <ItemInputCard
            id={"modeloTercero"}
            isLoading={isLoading}
            label={"Modelo"}
            value={modelo.value}
            onChange={handleChangeModelo}
            onBlur={handleBlur}
            focus={modelo.focus}
          />
          <ItemInputCard
            id={"daniosTercero"}
            isLoading={isLoading}
            label={"Daños al Vehículo"}
            value={danios.value}
            onChange={handleChangeDanios}
            onBlur={handleBlur}
            focus={danios.focus}
            multiline
          />
        </DetalleCard>
      )}
    </>
  );
};

export default DatosTerceroCard;
