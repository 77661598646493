import React, { createContext, useState, useContext, useEffect } from "react";

import { WebAuth } from "auth0-js";
import environment from "~libs/environment";

import { authStateSync } from "~components/auth/actions";
import { useDispatch } from "react-redux";
import localRepository from "../../localRepository";

const generateAuth = () =>
  new WebAuth({
    domain: environment.auth0.domain,
    clientID: environment.auth0.clientId,
    redirectUri: `${environment.myUrl}/callback`,
    responseType: "token id_token",
    scope: "openid profile email offline_access",
    audience: `http://mg-group.com.ar/apis`
  });

const Auth0Context = createContext(null);

const useAuthState = () => {
  const initialState = {
    accessToken: localRepository.accessToken.get(),
    idToken: null,
    expiresAt: 0,
    user: null
  };
  const [authState, setAuthState] = useState(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authStateSync(authState));
  }, [dispatch, authState]);

  return [authState, setAuthState];
};

const useContextValue = () => {
  const [authState, updateAuthState] = useAuthState();
  return {
    auth0: generateAuth(),
    authState,
    updateAuthState
  };
};

export const Auth0Provider = ({ children }) => {
  const value = useContextValue();
  return (
    <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
  );
};

export const useAuth0Context = () => {
  return useContext(Auth0Context);
};
