import React from "react";
import { makeStyles } from "@material-ui/core";
import EmptyStateImg from "~assets/ilustrations/empty-state.svg";
import { useSelector } from "react-redux";
import GalleryWithLightbox from "~styled/gallery/GalleryWithLightBox";
import PhotoIcon from "@material-ui/icons/Photo";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      paddingTop: "5.5%",
    },
    body: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    cardsAndCuotasContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      "@media (max-width:1024px)": {
        width: "100%",
        flexWrap: "wrap",
      },
    },
    cardHeader: {
      display: "flex",
      flexWrap: "wrap",
      padding: "2%",
      borderBottom: "1px solid #6240a5",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "5px",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: theme.colours.white,
      padding: "2%",
    },
    cards: {
      display: "flex",
      paddingBottom: "30px",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      "@media (max-width:1024px)": {
        width: "100%",
        flexWrap: "wrap",
      },
    },
    cardIcon: {
      color: "#6240a5",
      marginRight: "1%",
    },
    cardTitle: {
      fontFamily: "Nunito",
      fontSize: "1.3em",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.36,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#6240a5",
    },
    emptyText: {
      fontSize: pxToRem(16),
      fontWeight: 600,
      lineHeight: 1.38,
      color: "#757575",
      padding: pxToRem(20),
    },
    containerEmptyText: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
    },
  };
});

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.detalleSolicitudEmisionReducer,
  }));

const Documentacion = () => {
  const classes = useStyles();
  const { detalleSolicitudEmision, isLoading } = useReduxSelector();

  const photos = [];

  if (!isLoading) {
    if (detalleSolicitudEmision.fotosVehiculo)
      detalleSolicitudEmision.fotosVehiculo.map((fotoVehiculo) => {
        let photo = {
          src: fotoVehiculo,
          thumbnail: fotoVehiculo,
          thumbnailWidth: 80,
          thumbnailHeight: 80,
        };

        photos.push(photo);
        return photos;
      });
  }

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.cardsAndCuotasContainer}>
          <CardsColumn>
            {detalleSolicitudEmision.fotosVehiculo && (
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <PhotoIcon className={classes.cardIcon} />
                  <Typography className={classes.cardTitle}>
                    {"Fotos del Vehículo"}
                  </Typography>
                </div>
                <div>
                  <GalleryWithLightbox imagenes={photos} />
                </div>
              </div>
            )}
            {!detalleSolicitudEmision.fotosVehiculo && (
              <div className={classes.containerEmptyText}>
                <img
                  alt="empty-state-documentacion"
                  width={350}
                  src={EmptyStateImg}
                />
                <Typography noWrap className={classes.emptyText}>
                  No hay documentación disponible.
                </Typography>
              </div>
            )}
          </CardsColumn>
        </div>
      </div>
    </div>
  );
};

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};

export default Documentacion;
