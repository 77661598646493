import { createAction, handleActions } from "redux-actions";
import localRepository from "../../localRepository";

const clearAuthState = createAction("AUTH_STATE_CLEAR");

const authStateSuccess = createAction("AUTH_STATE_SYNC", (authState) => ({
  authState,
}));

const authStateSync = (authState) => {
  return async (dispatch, getState, services) => {
    try {
      localRepository.accessToken.set(authState.accessToken);
      if (!authState.accessToken) {
        localRepository.clear();
      }

      dispatch(authStateSuccess(authState));
    } catch (error) {
      dispatch(signInFail());
    }
  };
};

const signInInit = createAction("SIGN_IN_INIT");
const signInAction = createAction("SIGN_IN");
const signInSuccess = createAction("SIGN_IN_SUCCESS");
const signInConfirming = createAction("SIGN_IN_CONFIRMING");
const signInFail = createAction("SIGN_IN_FAIL");

const signInWithSocial = (callback) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(signInAction());

      callback();
    } catch (error) {
      console.error(error);
    }
  };
};

const postOnboardingInited = createAction("ONBOARDING_INITED");

const postOnboardingCompleted = createAction(
  "ONBOARDING_COMPLETED",
  (userIsValid) => ({ userIsValid })
);
const postOnboardingFailed = createAction("ONBOARDING_FAILED", (error) => ({
  error,
}));

const postOnboarding = () => {
  return async (dispatch, getState, services) => {
    try {
      const validating = getState().authReducer.validating;
      if (!validating) {
        dispatch(postOnboardingInited());

        const response = await services.api.usuarios().postOnboarding();

        dispatch(postOnboardingCompleted(response.usuarioValido));
      }
    } catch (error) {
      console.error(error);
      dispatch(postOnboardingFailed(error));
    }
  };
};

const initialState = {
  authState: null,
  isAuthenticated: false,
  isConfirming: false,
  isLoading: false,
  validating: false,
  validated: false,
  isAuthorized: false,
};

const authReducer = handleActions(
  {
    [clearAuthState]: (state, action) => {
      return initialState;
    },
    [postOnboardingCompleted]: (state, action) => {
      return {
        ...state,
        validating: false,
        isAuthorized: action.payload.userIsValid,
        validated: true,
      };
    },
    [postOnboardingInited]: (state, action) => {
      return {
        ...state,
        validating: true,
        validated: false,
      };
    },
    [postOnboardingFailed]: (state, action) => {
      return {
        ...state,
        validating: false,
      };
    },
    [authStateSuccess]: (state, action) => {
      return {
        ...state,
        authState: action.payload.authState,
        isLoading: false,
        isAuthenticated: action.payload.authState ? true : false,
      };
    },
    [signInInit]: () => {
      return { ...initialState };
    },
    [signInAction]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [signInConfirming]: (state, action) => {
      return {
        ...state,
        isConfirming: true,
        isLoading: false,
      };
    },
    [signInSuccess]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isConfirming: true,
      };
    },
    [signInFail]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isConfirming: false,
      };
    },
  },
  initialState
);

export default authReducer;
export {
  authStateSync,
  signInWithSocial,
  signInInit,
  postOnboarding,
  clearAuthState,
};
