import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { queueMessage } from "~shared/snackbarsProvider/actions";

import {
  postSiniestro,
  setSubmitting,
  clearData,
  setTipo,
  setNumeroDocumentoAsegurado,
  setNombreAsegurado,
  setTelefono,
  setMail,
  setCompania,
  setNumeroPoliza,
  setCalle,
  setAltura,
  setHora,
  setFecha,
  setLocalidad,
  setProvincia,
  setCodigoPostal,
  setLesionesFisicas,
  setComoOcurrio,
  setDaniosVehiculo,
  setNombreConductor,
  setApellidoConductor,
  setNumeroDocumentoConductor,
  setDominioTercero,
} from "./actions";
import { paths, siniestrosPath } from "~constants/referenceNames";

import Layout from "~shared/layout/Layout";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";
import { Tooltip, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";

import BusquedaCard from "./cards/BusquedaCard";
import DatosSiniestroCard from "./cards/DatosSiniestroCard";
import DatosAseguradoCard from "./cards/DatosAseguradoCard";
import DatosConductorCard from "./cards/DatosConductorCard";
import DatosAutomotorCard from "./cards/DatosAutomotorCard";
import FechaLugarCard from "./cards/FechaLugarCard";
import DetalleSiniestroCard from "./cards/DetalleSiniestroCard";
import DatosTerceroCard from "./cards/DatosTerceroCard";
import DocumentacionCard from "./cards/DocumentacionCard";
import SuccessDialog from "./SuccessDialog";
import { CHOQUE } from "~constants/tipoSiniestro";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "1.5%",
    minWidth: "10px",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  busquedaContainer: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "50%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  btnFinalizar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  cardItem: { minWidth: 300 },
}));

const useReduxSelector = () =>
  useSelector((state) => {
    return {
      isLoading: state.cargaSiniestroReducer.isLoading,
      creatingSiniestro: state.cargaSiniestroReducer.creatingSiniestro,
      siniestroCreado: state.cargaSiniestroReducer.siniestroCreado,
      poliza: state.cargaSiniestroReducer.polizaVinculada,

      tipo: state.cargaSiniestroReducer.tipoSelected,
      compania: state.cargaSiniestroReducer.companiaSelected,
      numeroSiniestroCompania:
        state.cargaSiniestroReducer.numeroSiniestroCompaniaSelected,
      numeroPoliza: state.cargaSiniestroReducer.numeroPolizaSelected,
      idPoliza: state.cargaSiniestroReducer.idPolizaSelected,
      sucursal: state.cargaSiniestroReducer.sucursalSelected,
      oficinaId: state.cargaSiniestroReducer.oficinaIdSelected,
      nombreAsegurado: state.cargaSiniestroReducer.nombreAseguradoSelected,
      numeroDocumentoAsegurado:
        state.cargaSiniestroReducer.numeroDocumentoAseguradoSelected,
      telefono: state.cargaSiniestroReducer.telefonoSelected,
      mail: state.cargaSiniestroReducer.mailSelected,
      fecha: state.cargaSiniestroReducer.fechaSelected,
      hora: state.cargaSiniestroReducer.horaSelected,
      calle: state.cargaSiniestroReducer.calleSelected,
      altura: state.cargaSiniestroReducer.alturaSelected,
      localidad: state.cargaSiniestroReducer.localidadSelected,
      provincia: state.cargaSiniestroReducer.provinciaSelected,
      codigoPostal: state.cargaSiniestroReducer.codigoPostalSelected,
      lesionesFisicas: state.cargaSiniestroReducer.lesionesFisicasSelected,
      comoOcurrio: state.cargaSiniestroReducer.comoOcurrioSelected,
      daniosVehiculo: state.cargaSiniestroReducer.daniosVehiculoSelected,
      marca: state.cargaSiniestroReducer.marcaSelected,
      modeloAnio: state.cargaSiniestroReducer.modeloAnioSelected,
      dominio: state.cargaSiniestroReducer.dominioSelected,
      modelo: state.cargaSiniestroReducer.modeloSelected,
      nombreConductor: state.cargaSiniestroReducer.nombreConductorSelected,
      apellidoConductor: state.cargaSiniestroReducer.apellidoConductorSelected,
      numeroDocumentoConductor:
        state.cargaSiniestroReducer.numeroDocumentoConductorSelected,
      documentos: state.cargaSiniestroReducer.documentosSelected,
      nombreTercero: state.cargaSiniestroReducer.nombreTerceroSelected,
      apellidoTercero: state.cargaSiniestroReducer.apellidoTerceroSelected,
      numeroDocumentoTercero:
        state.cargaSiniestroReducer.numeroDocumentoTerceroSelected,
      numeroPolizaTercero:
        state.cargaSiniestroReducer.numeroPolizaTerceroSelected,
      companiaTercero: state.cargaSiniestroReducer.companiaTerceroSelected,
      telefonoTercero: state.cargaSiniestroReducer.telefonoTerceroSelected,
      dominioTercero: state.cargaSiniestroReducer.dominioTerceroSelected,
      marcaTercero: state.cargaSiniestroReducer.marcaTerceroSelected,
      modeloTercero: state.cargaSiniestroReducer.modeloTerceroSelected,
      daniosTercero: state.cargaSiniestroReducer.daniosTerceroSelected,
      isProcessingDocumentos:
        state.cargaSiniestroReducer.isProcessingDocumentos,
    };
  });

const CargaSiniestro = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isLoading,
    creatingSiniestro,
    siniestroCreado,
    tipo,
    compania,
    numeroPoliza,
    idPoliza,
    sucursal,
    oficinaId,
    mail,
    telefono,
    numeroDocumentoAsegurado,
    nombreAsegurado,
    fecha,
    hora,
    calle,
    altura,
    localidad,
    provincia,
    codigoPostal,
    lesionesFisicas,
    comoOcurrio,
    daniosVehiculo,
    nombreConductor,
    apellidoConductor,
    numeroDocumentoConductor,
    documentos,
    modelo,
    modeloAnio,
    marca,
    dominio,
    nombreTercero,
    apellidoTercero,
    numeroDocumentoTercero,
    numeroPolizaTercero,
    companiaTercero,
    telefonoTercero,
    dominioTercero,
    marcaTercero,
    modeloTercero,
    daniosTercero,
    isProcessingDocumentos,
  } = useReduxSelector();

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
    //eslint-disable-next-line
  }, [dispatch]);

  const handleGuardarSolicitud = () => {
    dispatch(setSubmitting(true));

    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    let inputs = [
      { setFocusFunction: setTipo, ...tipo },
      { setFocusFunction: setCompania, ...compania },
      { setFocusFunction: setNumeroPoliza, ...numeroPoliza },
      { setFocusFunction: setNombreAsegurado, ...nombreAsegurado },
      {
        setFocusFunction: setNumeroDocumentoAsegurado,
        ...numeroDocumentoAsegurado,
      },
      { setFocusFunction: setMail, ...mail },
      { setFocusFunction: setTelefono, ...telefono },
      { setFocusFunction: setFecha, ...fecha },
      { setFocusFunction: setHora, ...hora },
      { setFocusFunction: setCalle, ...calle },
      { setFocusFunction: setAltura, ...altura },
      { setFocusFunction: setLocalidad, ...localidad },
      { setFocusFunction: setProvincia, ...provincia },
      { setFocusFunction: setCodigoPostal, ...codigoPostal },
      { setFocusFunction: setLesionesFisicas, ...lesionesFisicas },
      { setFocusFunction: setComoOcurrio, ...comoOcurrio },
      { setFocusFunction: setDaniosVehiculo, ...daniosVehiculo },
      { setFocusFunction: setNombreConductor, ...nombreConductor },
      { setFocusFunction: setApellidoConductor, ...apellidoConductor },
      {
        setFocusFunction: setNumeroDocumentoConductor,
        ...numeroDocumentoConductor,
      },
    ];

    tipo.value === CHOQUE && inputs.push({ setFocusFunction: setDominioTercero, ...dominioTercero });

    let inputToFocus = inputs.find((input) => !input.valid);

    if (inputToFocus)
      dispatch(
        inputToFocus.setFocusFunction({
          value: inputToFocus.value,
          valid: inputToFocus.valid,
          focus: true,
        })
      );
    if (inputToFocus) {
      dispatch(queueMessage("Hay campos sin completar o no válidos"));
      return;
    }

    if (isProcessingDocumentos) {
      dispatch(
        queueMessage(
          "Aguarda a que todos los archivos se hayan subido completamente."
        )
      );
      return;
    }

    let data = {
      tipo: tipo.value,
      compania: compania.value,
      numeroPoliza: numeroPoliza.value,
      idPoliza: idPoliza.value,
      sucursal: sucursal.value,
      oficinaId: oficinaId.value,
      fechaSiniestro: fecha.value,
      hora: hora.value,
      calle: calle.value,
      altura: altura.value,
      provincia: provincia.value,
      localidad: localidad.value,
      codigoPostal: codigoPostal.value,
      lesionesFisicas: lesionesFisicas.value,
      comoOcurrio: comoOcurrio.value,
      daniosVehiculoAsegurado: daniosVehiculo.value,
      numeroDocumentoAsegurado: numeroDocumentoAsegurado.value,
      nombreAsegurado: nombreAsegurado.value,
      email: mail.value,
      telefono: telefono.value,
      dominio: dominio.value,
      modeloAnio: modeloAnio.value,
      marca: marca.value,
      modelo: modelo.value,
      nombreConductor: nombreConductor.value,
      apellidoConductor: apellidoConductor.value,
      numeroDocumentoConductor: numeroDocumentoConductor.value,
      nombreTercero: nombreTercero.value,
      apellidoTercero: apellidoTercero.value,
      numeroDocumentoTercero: numeroDocumentoTercero.value,
      companiaTercero: companiaTercero.value,
      numeroPolizaTercero: numeroPolizaTercero.value,
      telefonoTercero: telefonoTercero.value,
      dominioTercero: dominioTercero.value,
      marcaTercero: marcaTercero.value,
      modeloTercero: modeloTercero.value,
      daniosTercero: daniosTercero.value,
      documentacion: documentos,
    };
    dispatch(postSiniestro(data));
  };

  const backAction = () => {
    history.push(paths[siniestrosPath]);
  };

  return (
    <>
      <Layout
        fixed
        title={"Carga de Siniestro"}
        backAction={backAction}
        data={
          <div className={classes.btnFinalizar}>
            <Tooltip title={"Cargar Siniestro"}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGuardarSolicitud}
                  startIcon={<SaveIcon />}
                >
                  {"CARGAR SINIESTRO"}
                </Button>
              </span>
            </Tooltip>
          </div>
        }
      >
        {creatingSiniestro && <LoadingViewGears />}
        {!creatingSiniestro && (
          <div className={classes.root}>
            <div className={classes.body}>
              <div
                onClick={() => {
                  history.push(`/siniestros`);
                }}
              >
                <SuccessDialog
                  openDialog={siniestroCreado}
                  value={"Siniestro cargado con éxito"}
                />
              </div>
              <div className={classes.busquedaContainer}>
                <BusquedaCard isLoading={isLoading} />
              </div>
              <div className={classes.cardsAndCuotasContainer}>
                <CardsColumn>
                  <DatosSiniestroCard isLoading={isLoading} />
                  <FechaLugarCard isLoading={isLoading} />
                  <DetalleSiniestroCard isLoading={isLoading} />
                  <DocumentacionCard isLoading={isLoading} />
                </CardsColumn>
                <CardsColumn>
                  <DatosAseguradoCard isLoading={isLoading} />
                  <DatosAutomotorCard isLoading={isLoading} />
                  <DatosConductorCard isLoading={isLoading} />
                  <DatosTerceroCard isLoading={isLoading} />
                </CardsColumn>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default CargaSiniestro;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
